/** @format */

import {
  ALERT_ABSENCES_LOAD,
  ALERT_CANCEL,
  ALERT_COUNT,
  ALERT_ERROR,
  ALERT_GRADES_LOAD,
} from '../actions/types';

const initialState = {
  absencesAlert: [],
  gradesAlert: [],
  alertCount: {},
  gradesAlertCount: null,
  absencesAlertCount: null,
  alertCancel: [],
};

export default function followupAlert(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ALERT_COUNT:
      return { ...state, alertCount: payload };
    case ALERT_ABSENCES_LOAD:
      return { ...state, absencesAlert: payload?.alerts, absencesAlertCount: payload?.count};
    case ALERT_GRADES_LOAD:
      return { ...state, gradesAlert: payload?.alerts, gradesAlertCount: payload?.count };
    case ALERT_CANCEL:
      return { ...state, alertCancel: payload };
    case ALERT_ERROR:
      return state;
    default:
      return state;
  }
}
