export const environments = {
  CI: 'CI',
  DEV: 'dev',
  PROD: 'production',
  STAGING: 'staging',
  TEST: 'test',
};

export const oldUserStates = {
  ACTIVE: {
    value: 'ACTIVE',
    display: {
      selectValue: 'Profils actifs',
      shortValue: 'Actif',
    },
  },
  PARTIALLY_REJECT: {
    value: 'PARTIALLY_REJECT',
    display: {
      selectValue: 'Dossiers partiellement rejetés',
      shortValue: 'Partiellement rejeté',
    },
  },
  CREATED: {
    value: 'CREATED',
    display: {
      selectValue: 'En attente de validations de mail',
      shortValue: 'Mail non confirmé',
    },
  },
  LEARNING: {
    value: 'LEARNING',
    display: {
      selectValue: 'Candidats master en cours de renforcement',
      shortValue: 'En renforcement',
    },
  },
  VALID_APPLICATION: {
    value: 'VALID_APPLICATION',
    display: {
      selectValue: 'Dossier master en attente de sélection',
      shortValue: 'Validé',
    },
  },
  SELECTED: {
    value: 'SELECTED',
    display: {
      selectValue:
        'Dossier master en attente de paiement de frais de cours de renforcement',
      shortValue: 'En attente de paiement renforcement',
    },
  },
  SUCCEEDED: {
    value: 'SUCCEEDED',
    display: {
      selectValue:
        "Dossiers master en attente de paiement des frais d'incription",
      shortValue: 'En attente de paiement inscription master',
    },
  },
  APPLYING: {
    value: 'APPLYING',
    display: {
      selectValue: 'Dossiers non soumis',
      shortValue: 'Non soumis',
    },
  },
  SUBMITTED: {
    value: 'SUBMITTED',
    display: {
      selectValue: 'Dossiers soumis',
      shortValue: 'Soumis',
    },
  },
  REJECTED: {
    value: 'REJECTED',
    display: {
      selectValue: 'Dossiers rejetés',
      shortValue: 'Rejeté',
    },
  },
  CONFIRMED: {
    value: 'CONFIRMED',
    display: {
      selectValue: "Dossiers en attente de paiement des frais d'inscription",
      shortValue: 'En attente de paiement inscription',
    },
  },
  DEACTIVATED: {
    value: 'DEACTIVATED',
    display: {
      selectValue: 'Profils non actifs',
      shortValue: 'Non actifs',
    },
  },
};

export const userStates = {
  ACTIVE: {
    value: 'ACTIVE',
    display: {
      selectValue: 'Profils actifs',
      shortValue: 'Actif',
    },
  },
  CREATED: {
    value: 'CREATED',
    display: {
      selectValue: 'En attente de validations de mail',
      shortValue: 'Mail non confirmé',
    },
  },
  SUBMITTED: {
    value: 'SUBMITTED',
    display: {
      selectValue: 'Dossiers en attente de validation',
      shortValue: 'En attente de validation',
    },
  },
  CONFIRMED: {
    value: 'CONFIRMED',
    display: {
      selectValue: 'Dossiers non soumis',
      shortValue: 'Non soumis',
    },
  },
  DEACTIVED: {
    value: 'DEACTIVED',
    display: {
      selectValue: 'Profils désactivés',
      shortValue: 'désactivé',
    },
  },
};

export const userTypeEnum = {
  ADMIN: 'admin',
  PROFESSOR: 'professor',
  STUDENT: 'student',
  SUPERADMIN: 'superadmin',
};

export const cyclesEnum = ['SECONDAIRE', 'LICENCE', 'MASTER'];

export const nationalityList = [
  'Afghane',
  'Albanaise',
  'Algérienne',
  'Allemande',
  'Américaine',
  'Andorrane',
  'Angolaise',
  'Antiguaise-et-Barbudienne',
  'Argentine',
  'Arménienne',
  'Australienne',
  'Autrichienne',
  'Azerbaïdjanaise',
  'Bahamienne',
  'Bahreinienne',
  'Bangladaise',
  'Barbadienne',
  'Belge',
  'Belizienne',
  'Béninoise',
  'Bhoutanaise',
  'Biélorusse',
  'Birmane',
  'Bissau-Guinéenne',
  'Bolivienne',
  'Bosnienne',
  'Botswanaise',
  'Brésilienne',
  'Britannique',
  'Brunéienne',
  'Bulgare',
  'Burkinabée',
  'Burundaise',
  'Cambodgienne',
  'Camerounaise',
  'Canadienne',
  'Cap-verdienne',
  'Centrafricaine',
  'Chilienne',
  'Chinoise',
  'Chypriote',
  'Colombienne',
  'Comorienne',
  'Congolaise',
  'Congolaise',
  'Cookienne',
  'Costaricaine',
  'Croate',
  'Cubaine',
  'Danoise',
  'Djiboutienne',
  'Dominicaine',
  'Dominiquaise',
  'Égyptienne',
  'Émirienne',
  'Équato-guineenne',
  'Équatorienne',
  'Érythréenne',
  'Espagnole',
  'Est-timoraise',
  'Estonienne',
  'Éthiopienne',
  'Fidjienne',
  'Finlandaise',
  'Française',
  'Gabonaise',
  'Gambienne',
  'Georgienne',
  'Ghanéenne',
  'Grenadienne',
  'Guatémaltèque',
  'Guinéenne',
  'Guyanienne',
  'Haïtienne',
  'Hellénique',
  'Hondurienne',
  'Hongroise',
  'Indienne',
  'Indonésienne',
  'Irakienne',
  'Iranienne',
  'Irlandaise',
  'Islandaise',
  'Israélienne',
  'Italienne',
  'Ivoirienne',
  'Jamaïcaine',
  'Japonaise',
  'Jordanienne',
  'Kazakhstanaise',
  'Kenyane',
  'Kirghize',
  'Kiribatienne',
  'Kittitienne et Névicienne',
  'Koweïtienne',
  'Laotienne',
  'Lesothane',
  'Lettone',
  'Libanaise',
  'Libérienne',
  'Libyenne',
  'Liechtensteinoise',
  'Lituanienne',
  'Luxembourgeoise',
  'Macédonienne',
  'Malaisienne',
  'Malawienne',
  'Maldivienne',
  'Malgache',
  'Maliennes',
  'Maltaise',
  'Marocaine',
  'Marshallaise',
  'Mauricienne',
  'Mauritanienne',
  'Mexicaine',
  'Micronésienne',
  'Moldave',
  'Monegasque',
  'Mongole',
  'Monténégrine',
  'Mozambicaine',
  'Namibienne',
  'Nauruane',
  'Néerlandaise',
  'Néo-Zélandaise',
  'Népalaise',
  'Nicaraguayenne',
  'Nigériane',
  'Nigérienne',
  'Niuéenne',
  'Nord-coréenne',
  'Norvégienne',
  'Omanaise',
  'Ougandaise',
  'Ouzbéke',
  'Pakistanaise',
  'Palaosienne',
  'Palestinienne',
  'Panaméenne',
  'Papouane-Néo-Guinéenne',
  'Paraguayenne',
  'Péruvienne',
  'Philippine',
  'Polonaise',
  'Portugaise',
  'Qatarienne',
  'Roumaine',
  'Russe',
  'Rwandaise',
  'Saint-Lucienne',
  'Saint-Marinaise',
  'Saint-Vincentaise et Grenadine',
  'Salomonaise',
  'Salvadorienne',
  'Samoane',
  'Santoméenne',
  'Saoudienne',
  'Sénégalaise',
  'Serbe',
  'Seychelloise',
  'Sierra-Léonaise',
  'Singapourienne',
  'Slovaque',
  'Slovène',
  'Somalienne',
  'Soudanaise',
  'Sri-Lankaise',
  'Sud-Africaine',
  'Sud-Coréenne',
  'Sud-Soudanaise',
  'Suédoise',
  'Suisse',
  'Surinamaise',
  'Swazie',
  'Syrienne',
  'Tadjike',
  'Tanzanienne',
  'Tchadienne',
  'Tchèque',
  'Thaïlandaise',
  'Togolaise',
  'Tonguienne',
  'Trinidadienne',
  'Tunisienne',
  'Turkmène',
  'Turque',
  'Tuvaluane',
  'Ukrainienne',
  'Uruguayenne',
  'Vanuatuane',
  'Vaticane',
  'Vénézuélienne',
  'Vietnamienne',
  'Yéménite',
  'Zambienne',
  'Zimbabwéenne',
];

export const countryList = [
  'Afghanistan',
  'Albanie',
  'Algérie',
  'Allemagne',
  'États-Unis',
  'Andorre',
  'Angola',
  'Antigua-et-Barbuda',
  'Argentine',
  'Arménie',
  'Australie',
  'Autriche',
  'Azerbaïdjan',
  'Bahamas',
  'Bahreïn',
  'Bangladesh',
  'Barbade',
  'Belgique',
  'Belize',
  'Bénin',
  'Bhoutan',
  'Biélorussie',
  'Birmanie',
  'Guinée-Bissau',
  'Bolivie',
  'Bosnie-Herzégovine',
  'Botswana',
  'Brésil',
  'Royaume-Uni',
  'Brunéi',
  'Bulgarie',
  'Burkina',
  'Burundi',
  'Cambodge',
  'Cameroun',
  'Canada',
  'Cap-Vert',
  'Centrafrique',
  'Chili',
  'Chine',
  'Chypre',
  'Colombie',
  'Comores',
  'Congo-Kinshasa',
  'Congo-Brazzaville',
  'Îles Cook',
  'Costa Rica',
  'Croatie',
  'Cuba',
  'Danemark',
  'Djibouti',
  'République dominicaine',
  'Dominique',
  'Égypte',
  'Émirats arabes unis',
  'Guinée équatoriale',
  'Équateur',
  'Érythrée',
  'Espagne',
  'Timor-Leste',
  'Estonie',
  'Éthiopie',
  'Fidji',
  'Finlande',
  'France',
  'Gabon',
  'Gambie',
  'Géorgie',
  'Ghana',
  'Grenade',
  'Guatemala',
  'Guinée',
  'Guyana',
  'Haïti',
  'Grèce',
  'Honduras',
  'Hongrie',
  'Inde',
  'Indonésie',
  'Iraq',
  'Iran',
  'Irlande',
  'Islande',
  'Israël',
  'Italie',
  "Côte d'Ivoire",
  'Jamaïque',
  'Japon',
  'Jordanie',
  'Kazakhstan',
  'Kenya',
  'Kirghizistan',
  'Kiribati',
  'Saint-Christophe-et-Niévès',
  'Koweït',
  'Laos',
  'Lesotho',
  'Lettonie',
  'Liban',
  'Libéria',
  'Libye',
  'Liechtenstein',
  'Lituanie',
  'Luxembourg',
  'Macédoine',
  'Malaisie',
  'Malawi',
  'Maldives',
  'Madagascar',
  'Mali',
  'Malte',
  'Maroc',
  'Îles Marshall',
  'Maurice',
  'Mauritanie',
  'Mexique',
  'Micronésie',
  'Moldovie',
  'Monaco',
  'Mongolie',
  'Monténégro',
  'Mozambique',
  'Namibie',
  'Nauru',
  'Pays-Bas',
  'Nouvelle-Zélande',
  'Népal',
  'Nicaragua',
  'Nigéria',
  'Niger',
  'Niue',
  'Corée du Nord',
  'Norvège',
  'Oman',
  'Ouganda',
  'Ouzbékistan',
  'Pakistan',
  'Palaos',
  'Palestine',
  'Panama',
  'Papouasie-Nouvelle-Guinée',
  'Paraguay',
  'Pérou',
  'Philippines',
  'Pologne',
  'Portugal',
  'Qatar',
  'Roumanie',
  'Russie',
  'Rwanda',
  'Sainte-Lucie',
  'Saint-Marin',
  'Saint-Vincent-et-les Grenadines',
  'Îles Salomon',
  'Salvador',
  'Samoa',
  'Sao Tomé-et-Principe',
  'Arabie saoudite',
  'Sénégal',
  'Serbie',
  'Seychelles',
  'Sierra Leone',
  'Singapour',
  'Slovaquie',
  'Slovénie',
  'Somalie',
  'Soudan',
  'Sri Lanka',
  'Afrique du Sud',
  'Corée du Sud',
  'Soudan du Sud',
  'Suède',
  'Suisse',
  'Suriname',
  'Swaziland',
  'Syrie',
  'Tadjikistan',
  'Tanzanie',
  'Tchad',
  'Tchéquie',
  'Thaïlande',
  'Togo',
  'Tonga',
  'Trinité-et-Tobago',
  'Tunisie',
  'Turkménistan',
  'Turquie',
  'Tuvalu',
  'Ukraine',
  'Uruguay',
  'Vanuatu',
  'Vatican',
  'Venezuela',
  'Viêt Nam',
  'Yémen',
  'Zambie',
  'Zimbabwe',
];

export const allTestRooms = [
  { value: 'A1-001', label: 'A1-001', capacity: 33 },
  { value: 'A1-101', label: 'A1-101', capacity: 36 },
  { value: 'A1-102', label: 'A1-102', capacity: 36 },
  { value: 'A1-103', label: 'A1-103', capacity: 36 },
  { value: 'A1-104', label: 'A1-104', capacity: 36 },
  { value: 'A1-201', label: 'A1-201', capacity: 42 },
  { value: 'A1-202', label: 'A1-202', capacity: 32 },
  { value: 'A1-203', label: 'A1-203', capacity: 32 },
  { value: 'A1-204', label: 'A1-204', capacity: 42 },
  { value: 'A1-205', label: 'A1-205', capacity: 32 },
  { value: 'A1-301', label: 'A1-301', capacity: 36 },
  { value: 'A1-302', label: 'A1-302', capacity: 32 },
  { value: 'A1-302+A1-303', label: 'A1-302+A1-303', capacity: 70 },
  { value: 'A1-303', label: 'A1-303', capacity: 32 },
  { value: 'A1-304', label: 'A1-304', capacity: 36 },
  { value: 'A1-305', label: 'A1-305', capacity: 32 },
  { value: 'A1-306', label: 'A1-306', capacity: 32 },
  { value: 'A3-101', label: 'A3-101', capacity: 37 },
  { value: 'A3-102', label: 'A3-102', capacity: 37 },
  { value: 'A3-103', label: 'A3-103', capacity: 37 },
  { value: 'A3-104', label: 'A3-104', capacity: 37 },
  { value: 'A3-201', label: 'A3-201', capacity: 37 },
  { value: 'A3-202', label: 'A3-202', capacity: 37 },
  { value: 'A3-203', label: 'A3-203', capacity: 38 },
  { value: 'A3-204', label: 'A3-204', capacity: 37 },
  { value: 'A3-205', label: 'A3-205', capacity: 37 },
  { value: 'A3-206', label: 'A3-206', capacity: 38 },
  { value: 'A3-301', label: 'A3-301', capacity: 37 },
  { value: 'A3-302', label: 'A3-302', capacity: 37 },
  { value: 'A3-303', label: 'A3-303', capacity: 37 },
  { value: 'A3-304', label: 'A3-304', capacity: 37 },
  { value: 'A3-305', label: 'A3-305', capacity: 37 },
  { value: 'A3-306', label: 'A3-306', capacity: 37 },
  { value: 'A3-401', label: 'A3-401', capacity: 37 },
  { value: 'A3-402', label: 'A3-402', capacity: 37 },
  { value: 'A3-403', label: 'A3-403', capacity: 37 },
  { value: 'A3-404', label: 'A3-404', capacity: 37 },
  { value: 'A3-405', label: 'A3-405', capacity: 37 },
  { value: 'A3-406', label: 'A3-406', capacity: 37 },
  { value: 'AmphiA', label: 'AmphiA', capacity: 200 },
  { value: 'AmphiB', label: 'AmphiB', capacity: 200 },
  { value: 'AmphiC', label: 'AmphiC', capacity: 80 },
  { value: 'Bibliothèque', label: 'Bibliothèque', capacity: 60 },
  { value: 'ELS Annexe 1', label: 'ELS Annexe 1', capacity: 200 },
  { value: 'ELS Annexe 2', label: 'ELS Annexe 2', capacity: 200 },
  { value: 'ELS Siège', label: 'ELS Siège', capacity: 200 },
  { value: 'Laboratoire', label: 'Laboratoire', capacity: 99999 },
  {
    value: 'Laboratoire de Recherche',
    label: 'Laboratoire de Recherche',
    capacity: 20,
  },
  { value: 'Laboratoire de TP', label: 'Laboratoire de TP', capacity: 40 },
  { value: 'S-101', label: 'S-101', capacity: 60 },
  { value: 'S-102', label: 'S-102', capacity: 60 },
  { value: 'S-103', label: 'S-103', capacity: 38 },
  { value: 'S-301', label: 'S-301', capacity: 40 },
  { value: 'S-302', label: 'S-302', capacity: 40 },
  { value: 'S-303', label: 'S-303', capacity: 38 },
  { value: 'S-304', label: 'S-304', capacity: 38 },
  { value: 'S-305', label: 'S-305', capacity: 38 },
  { value: 'S-306', label: 'S-306', capacity: 38 },
  { value: 'S-401', label: 'S-401', capacity: 38 },
  { value: 'S-402', label: 'S-402', capacity: 38 },
  { value: 'S-403', label: 'S-403', capacity: 38 },
  { value: 'S-406', label: 'S-406', capacity: 38 },
  {
    value: 'Salle Informatique Annexe',
    label: 'Salle Informatique Annexe',
    capacity: 42,
  },
  {
    value: 'Salle Informatique Siège',
    label: 'Salle Informatique Siège',
    capacity: 32,
  },
  { value: 'Salle Internet', label: 'Salle Internet', capacity: 44 },
  { value: 'Sortie Pédagogique', label: 'Sortie Pédagogique', capacity: 99999 },
  { value: 'TERRAIN', label: 'TERRAIN', capacity: 99999 },
  { value: 'Visite de Chantier', label: 'Visite de Chantier', capacity: 99999 },
  {
    value: 'Visite de magasin de Scaphandrier',
    label: 'Visite de magasin de Scaphandrier',
    capacity: 99999,
  },
];

export const compositionTypes = {
  DEVOIR: 'Devoir',
  EXAM: 'Examen',
  RATTRAPAGE: 'Rattrapage',
};

export const permissions = {
  STATISTICS_ABSENCE_COMPLETION_GET:
    'Récupérer les statistiques de complétion des absences',
  STATISTICS_GRADES_GET: 'Récupérer les statistiques des notes par classe',

  MEETING_MANAGE: 'Gérer les convocations',

  STATISTICS_COORDINATOR_GET: 'Récupérer les statistiques des coordinateurs',

  ABSENCE_ADD: 'Ajouter/modifier des absences',
  ABSENCE_FOLLOWUP: 'Gérer les alertes des absences',
  ABSENCE_GET: 'Récupérer les absences',

  ADMIN_CREATE: 'Créer un administrateur',
  ADMIN_DELETE: 'Supprimer un administrateur',
  ADMIN_PERMISSIONS_ADD: 'Attribuer des permissions à un administrateur',
  ADMIN_UPDATE: 'Modifier un administrateur',

  ARTICLE_ADD: 'Ajouter un article',
  ARTICLE_DELETE: 'Supprimer un article',
  ARTICLE_PUBLISH: 'Publier un article',
  ARTICLE_UPDATE: 'Modifier un article',

  BOOKS_MANAGE: 'Gérer les livres',

  COMPOSITION_CODE_GEN: 'Générer les codes de composition',
  COMPOSITION_CREATE: 'Créer des compositions',
  COMPOSITION_GET: 'Récupérer la liste des compositions',
  COMPOSITION_GEN_PAYMENT_LIST:
    'Générer la liste de ceux qui ont payé un rattrapage',
  COMPOSITION_STUDENT_AUTHORIZE: 'Autoriser un étudiant(composition)',
  COMPOSITION_STUDENT_DELETE: 'Supprimer un étudiant(composition)',
  COMPOSITION_STUDENT_DISTRIBUTE: 'Répartition des étudiants dans les salles',
  COMPOSITION_STUDENT_PAY: 'Valider un payement étudiant(composition)',
  COMPOSITION_UPDATE: 'Modifier une composition',

  COURSE_ADD: 'Ajouter un cours',
  COURSE_DELETE: 'Supprimer un cours',
  COURSE_PUBLISH: 'Publier un cours',
  COURSE_UPDATE: 'Modifier un cours',

  ALUMNI_MANAGE: 'Gérer les alumnis',

  INTERNSHIP_MANAGE: 'Gérer les stagiaires',

  CYCLE_MANAGE: 'Gérer les Cycles de formation',

  EVENT_MANAGE: 'Gérer les événements',

  FAQ_MANAGE: 'Gérer la FAQ',

  FIELDS_MANAGE: 'Gérer les filières',

  GALERY_MANAGE: 'Gérer la GALERY',

  HISTORY_READ: 'Consulter historiques',

  LOANS_MANAGE: 'Gérer les prêts',

  OTHERPAGE_MANAGE: 'Gérer les pages',

  PATNERSLOGOS_MANAGE: 'Gérer les logos des partenaires',

  PENALTY_MANAGE: 'Gérer les punitions',

  PROFESSOR_CREATE: 'Créer un professeur',
  PROFESSOR_DELETE: 'Supprimer un professeur',
  PROFESSOR_PASSWORD_GENERATE: 'Générer mot de passe professeur',
  PROFESSOR_SEND_MAIL: 'Envoyer un email d’inscription',
  PROFESSOR_UPDATE: 'Modifier un professeur',

  REGISTRATION_GET: 'Récupérer la liste des codes inscriptions',
  REGISTRATION_UPDATE: "Attribuer un code d'inscription",

  SESSION_GET: 'Récupérer les sessions de cours',

  SLIDES_MANAGE: 'Gérer les slides',

  STATISTICS_ABSENCE_GET: "Récupérer les statistiques d'absence des étudiants",

  STUDENT_APPLICATION_REVIEW: 'Traiter dossier inscription',
  STUDENT_CREATE: 'Créer un étudiant',
  STUDENT_DELETE: 'Supprimer un étudiant',
  STUDENT_PASSWORD_GENERATE: 'Générer mot de passe étudiant',
  STUDENT_PAYMENT_RELEASE: 'Validation paiement',
  STUDENT_STATUS_UPDATE: "Modifier le statut d'un étudiant",
  STUDENT_UPDATE: 'Modifier un étudiant',

  TEAM_MANAGE: 'Gérer le personnel',

  TEXTS_EDITION: 'Editer les textes',
};

export const penaltyTypes = {
  GROUP: 'Collective',
  SINGLE: 'Individuelle',
};

export const studentRegistrationSteps = {
  ANNEXMASTERDOCS: 'ANNEXMASTERDOCS',
  CONDITIONS: 'CONDITIONS',
  DIPLOMAS: 'DIPLOMAS',
  LEGALTUTORS: 'LEGALTUTORS',
  PERSONALINFO: 'PERSONALINFO',
  RESULT: 'RESULT',
  SCHOOLREPORTS: 'SCHOOLREPORTS',
  TRANSFERTPAPER: 'TRANSFERTPAPER',
};

export const adminRegistrationSteps = {
  DIPLOMAS: 'DIPLOMAS',
  LEGALTUTORS: 'LEGALTUTORS',
  OTHERDOCUMENTS: 'OTHERDOCUMENTS',
  PERSONALINFO: 'PERSONALINFO',
  SCHOOLREPORTS: 'SCHOOLREPORTS',
  RESULT: 'RESULT',
};

export const files = {
  birthCertificate: 'birthCertificate',
  coverLetters: 'coverLetters',
  diploma: 'diploma',
  diplomaGradeSheet: 'diplomaGradeSheet',
  englishCertificate: 'englishCertificate',
  gradeSheets: 'greatSheets',
  resume: 'resume',
  transfertPaper: 'transfertPaper',
};

export const studentStatus = {
  DISCOUNT: { code: 'RPI', label: 'Remise paiement intégral' },
  FOREIGN: { code: 'ETR', label: 'Etranger' },
  MUSTPAY: { code: 'TP', label: 'Titre Payant' },
  SCHOLARSHIP: { code: 'BRS', label: 'Boursiers' },
};

export const httpCodes = {
  OK: 200,
  CREATED: 201,
  OK_BUT_NO_CONTENT: 204,
  MOVED_PERMANENTLY: 301,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  // Used for unique fields violation
  CONFLICT: 409,
  // Used for required field violation, unallowed updates
  UNPROCESSABLE_ENTITY: 422,
  INTERNAL_SERVER_ERROR: 500,
};
