import { combineReducers } from 'redux';

import absence from './absence';
import account from './account';
import alumni from './alumni';
import article from './article';
import auth from './auth';
import book from './book';
import chat from './chat';
import contact from './contact';
import course from './course';
import cycleformation from './cycleFormation';
import composition from './composition';
import employee from './employee';
import event from './event';
import faq from './faq';
import field from './field';
import internship from './internship';
import loan from './loan';
import newsletter from './newsletter';
import otherPage from './otherPage';
import partnersLogo from './partnersLogo';
import passwordReset from './passwordReset';
import permission from './permission';
import photogallery from './photogallery';
import penalty from './penalty';
import slide from './slide';
import socket from './socket';
import text from './text';
import user from './user';
import userType from './role';
import registering from './registering';
import registration from './registration';
import student from './student';
import history from './history';
import followupAlert from './followupAlert';

export default combineReducers({
  absence,
  account,
  alumni,
  article,
  auth,
  book,
  chat,
  contact,
  course,
  cycleformation,
  employee,
  event,
  composition,
  faq,
  field,
  loan,
  internship,
  newsletter,
  otherPage,
  partnersLogo,
  passwordReset,
  permission,
  photogallery,
  penalty,
  registering,
  registration,
  student,
  userType,
  slide,
  socket,
  text,
  user,
  history,
  followupAlert
});
