import {
  USER_CLEAR,
  USER_DELETE,
  USER_ERROR,
  USER_FORM_CLEAR,
  USER_LOAD,
  USER_LOAD_SESSION,
  USER_LOADING,
  USER_PATCH,
  USER_POST,
  USERS_CLASS_ALLOCATION,
  USERS_PATCH_STATE,
} from '../actions/types';

const initialState = {
  users: [],
  loading: false,
  count: 0,
  userStatistics: [],
  form: {
    submitted: false,
    succeeded: false,
    password: null,
  },
  session: {}
};

const sortUsers = (users) =>
  users.sort((userA, userB) => {
    const lastnameA = userA.lastname.toUpperCase();
    const lastnameB = userB.lastname.toUpperCase();
    if (lastnameA === lastnameB) {
      const firstnameA = userA.firstname.toUpperCase();
      const firstnameB = userB.firstname.toUpperCase();
      return firstnameA > firstnameB ? 1 : -1;
    }
    return lastnameA > lastnameB ? 1 : -1;
  });

export default function user(state = initialState, action) {
  const { type, payload } = action;
  const { users, count, form } = state;

  switch (type) {
    case USER_LOADING:
      return {
        ...state,
        loading: true,
      };
    case USER_LOAD:
      return {
        ...state,
        loading: false,
        count: payload.count,
        users: payload.users,
        userStatistics: payload.statistics,
      };
    case USER_LOAD_SESSION:
      return {
        ...state,
        loading: false,
        count: payload.length,
        session: payload.session,
        users: payload.students,
      };
    case USER_POST:
      return {
        ...state,
        count: count + 1,
        users: sortUsers([...users, payload.user]),
        form: { submitted: true, succeeded: true, password: payload.password },
      };
    case USER_PATCH:
      return {
        ...state,
        users: sortUsers([
          ...users.filter(({ _id }) => _id !== payload.user._id),
          payload.user,
        ]),
        form: {
          submitted: true,
          succeeded: true,
          password: payload.fields.password ? payload.fields.password : null,
        },
      };
    case USERS_PATCH_STATE:
      return {
        ...state,
        loading: false,
        count: count - payload.length,
        users: sortUsers([
          ...users.filter(({ _id }) => !payload.includes(_id)),
        ]),
      };
    case USERS_CLASS_ALLOCATION:
      return {
        ...state,
        loading: false,
        users: payload,
      };
    case USER_DELETE:
      return {
        ...state,
        users: sortUsers([
          ...users.filter(({ _id }) => _id !== payload.user._id),
        ]),
      };
    case USER_ERROR:
      return {
        ...state,
        form:
          payload === 'post' || payload === 'patch'
            ? { submitted: true, succeeded: false, password: null }
            : form,
      };
    case USER_CLEAR:
      return initialState;
    case USER_FORM_CLEAR:
      return {
        ...state,
        form: initialState.form,
      };
    default:
      return state;
  }
}
