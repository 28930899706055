export const CONTACT_MAIL_SUCCESS = 'CONTACT_MAIL_SUCCESS';
export const CONTACT_MAIL_ERROR = 'CONTACT_MAIL_ERROR';

export const NEWSLETTER_SUBSCRIBE_SUCCESS = 'NEWSLETTER_SUBSCRIBE_SUCCESS';
export const NEWSLETTER_SUBSCRIBE_ERROR = 'NEWSLETTER_SUBSCRIBE_ERROR';

export const AUTH_LOAD_USER = 'AUTH_LOAD_USER';
export const AUTH_LOAD_USER_ERROR = 'AUTH_LOAD_USER_ERROR';
export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS';
export const AUTH_LOGIN_ERROR = 'AUTH_LOGIN_ERROR';
export const AUTH_LOGOUT_SUCCESS = 'AUTH_LOGOUT_SUCCESS';
export const AUTH_LOGOUT_ERROR = 'AUTH_LOGOUT_ERROR';
export const AUTH_EMAIL_CONFIRMATION = 'AUTH_EMAIL_CONFIRMATION';
export const AUTH_GENERATION_EMAIL_CONFIRMATION =
  'AUTH_GENERATION_EMAIL_CONFIRMATION';
// FIXME : This has nothing to do in AUTH, we must create USER
// and keep AUTH to the minimal
export const AUTH_UPDATE_PROFILEPIC = 'AUTH_UPDATE_PROFILEPIC';

export const PASSWORD_RESET_GENERATE_LINK_SUCCESS =
  'PASSWORD_RESET_GENERATE_LINK_SUCCESS';
export const PASSWORD_RESET_GENERATE_LINK_ERROR =
  'PASSWORD_RESET_GENERATE_LINK_ERROR';
export const PASSWORD_RESET_CHECK_LINK_SUCCESS =
  'PASSWORD_RESET_CHECK_LINK_SUCCESS';
export const PASSWORD_RESET_CHECK_LINK_ERROR =
  'PASSWORD_RESET_CHECK_LINK_ERROR';
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS';
export const PASSWORD_RESET_ERROR = 'PASSWORD_RESET_ERROR';
export const PASSWORD_RESET_CLEAR = 'PASSWORD_RESET_CLEAR';

export const REGISTERING_GENERATE_LINK_SUCCESS =
  'REGISTERING_GENERATE_LINK_SUCCESS';
export const REGISTERING_GENERATE_LINK_ERROR =
  'REGISTERING_GENERATE_LINK_ERROR';
export const REGISTERING_CHECK_LINK_SUCCESS = 'REGISTERING_CHECK_LINK_SUCCESS';
export const REGISTERING_CHECK_LINK_ERROR = 'REGISTERING_CHECK_LINK_ERROR';
export const REGISTERING_SUCCESS = 'REGISTERING_SUCCESS';
export const REGISTERING_ERROR = 'REGISTERING_ERROR';
export const REGISTERING_CLEAR = 'REGISTERING_CLEAR';

export const ACCOUNT_UPDATE = 'ACCOUNT_UPDATE';
export const ACCOUNT_UPDATE_ERROR = 'ACCOUNT_UPDATE_ERROR';
export const ACCOUNT_UPDATE_CLEAR = 'ACCOUNT_UPDATE_CLEAR';

export const DRIVE_FOLDER_GET = 'DRIVE_FOLDER_GET';
export const DRIVE_FOLDER_GET_ERROR = 'DRIVE_FOLDER_GET_ERROR';
export const DRIVE_FOLDER_GET_CLEAR = 'DRIVE_FOLDER_GET_CLEAR';

export const TEXT_LOAD = 'TEXT_LOAD';
export const TEXT_ADMIN_LOAD = 'TEXT_ADMIN_LOAD';
export const TEXT_POST = 'TEXT_POST';
export const TEXT_PATCH = 'TEXT_PATCH';
export const TEXT_DELETE = 'TEXT_DELETE';
export const TEXT_ERROR = 'TEXT_ERROR';
export const TEXT_CLEAR = 'TEXT_CLEAR';

export const SLIDE_LOAD = 'SLIDE_LOAD';
export const SLIDE_ADMIN_LOAD = 'SLIDE_ADMIN_LOAD';
export const SLIDE_POST = 'SLIDE_POST';
export const SLIDE_PATCH = 'SLIDE_PATCH';
export const SLIDE_DELETE = 'SLIDE_DELETE';
export const SLIDE_ERROR = 'SLIDE_ERROR';
export const SLIDE_CLEAR = 'SLIDE_CLEAR';

export const PHOTOGALLERY_LOADING = 'PHOTOGALLERY_LOADING';
export const PHOTOGALLERY_LOAD = 'PHOTOGALLERY_LOAD';
export const PHOTOGALLERY_ADMIN_LOAD = 'PHOTOGALLERY_ADMIN_LOAD';
export const PHOTOGALLERY_POST = 'PHOTOGALLERY_POST';
export const PHOTOGALLERY_PATCH = 'PHOTOGALLERY_PATCH';
export const PHOTOGALLERY_DELETE = 'PHOTOGALLERY_DELETE';
export const PHOTOGALLERY_ERROR = 'PHOTOGALLERY_ERROR';
export const PHOTOGALLERY_CLEAR = 'PHOTOGALLERY_CLEAR';

export const EVENT_LOAD = 'EVENT_LOAD';
export const EVENT_POST = 'EVENT_POST';
export const EVENT_PATCH = 'EVENT_PATCH';
export const EVENT_DELETE = 'EVENT_DELETE';
export const EVENT_ERROR = 'EVENT_ERROR';
export const EVENT_CLEAR = 'EVENT_CLEAR';

export const FAQ_LOAD = 'FAQ_LOAD';
export const FAQ_ADMIN_LOAD = 'FAQ_ADMIN_LOAD';
export const FAQ_POST = 'FAQ_POST';
export const FAQ_PATCH = 'FAQ_PATCH';
export const FAQ_DELETE = 'FAQ_DELETE';
export const FAQ_ERROR = 'FAQ_ERROR';
export const FAQ_CLEAR = 'FAQ_CLEAR';

export const OTHER_PAGES_LOAD = 'OTHER_PAGES_LOAD';
export const OTHER_PAGE_LOAD = 'OTHER_PAGE_LOAD';
export const OTHER_PAGE_POST = 'OTHER_PAGE_POST';
export const OTHER_PAGE_PATCH = 'OTHER_PAGE_PATCH';
export const OTHER_PAGE_DELETE = 'OTHER_PAGE_DELETE';
export const OTHER_PAGE_ERROR = 'OTHER_PAGE_ERROR';
export const OTHER_PAGE_CLEAR = 'OTHER_PAGE_CLEAR';

export const ARTICLE_LOAD = 'ARTICLE_LOAD';
export const ARTICLES_LOAD = 'ARTICLES_LOAD';
export const ARTICLE_POST = 'ARTICLE_POST';
export const ARTICLE_PATCH = 'ARTICLE_PATCH';
export const ARTICLE_DELETE = 'ARTICLE_DELETE';
export const ARTICLE_ERROR = 'ARTICLE_ERROR';
export const ARTICLE_CLEAR = 'ARTICLE_CLEAR';

export const CYCLE_FORMATION_LOAD = 'CYCLE_FORMATION_LOAD';
export const CYCLE_FORMATION_POST = 'CYCLE_FORMATION_POST';
export const CYCLE_FORMATION_PATCH = 'CYCLE_FORMATION_PATCH';
export const CYCLE_FORMATION_ERROR = 'CYCLE_FORMATION_ERROR';
export const CYCLE_FORMATION_CLEAR = 'CYCLE_FORMATION_CLEAR';

export const PARTNERS_LOGO_LOAD = 'PARTNERS_LOGO_LOAD';
export const PARTNERS_LOGO_POST = 'PARTNERS_LOGO_POST';
export const PARTNERS_LOGO_PATCH = 'PARTNERS_LOGO_PATCH';
export const PARTNERS_LOGO_DELETE = 'PARTNERS_LOGO_DELETE';
export const PARTNERS_LOGO_ERROR = 'PARTNERS_LOGO_ERROR';
export const PARTNERS_LOGO_CLEAR = 'PARTNERS_LOGO_CLEAR';

export const USER_LOADING = 'USER_LOADING';
export const USER_LOAD = 'USER_LOAD';
export const USER_LOAD_SESSION = 'USER_LOAD_SESSION';
export const USER_POST = 'USER_POST';
export const USER_PATCH = 'USER_PATCH';
export const USERS_PATCH_STATE = 'USERS_PATCH_STATE';
export const USERS_CLASS_ALLOCATION = 'USERS_CLASS_ALLOCATION';
export const USER_DELETE = 'USER_DELETE';
export const USER_ERROR = 'USER_ERROR';
export const USER_CLEAR = 'USER_CLEAR';
export const USER_FORM_CLEAR = 'USER_FORM_CLEAR';

export const EMPLOYEE_LOAD = 'EMPLOYEE_LOAD';
export const EMPLOYEE_POST = 'EMPLOYEE_POST';
export const EMPLOYEE_PATCH = 'EMPLOYEE_PATCH';
export const EMPLOYEE_DELETE = 'EMPLOYEE_DELETE';
export const EMPLOYEE_ERROR = 'EMPLOYEE_ERROR';
export const EMPLOYEE_CLEAR = 'EMPLOYEE_CLEAR';

export const ROLE_LOAD = 'ROLE_LOAD';
export const ROLE_POST = 'ROLE_POST';
export const ROLE_PATCH = 'ROLE_PATCH';
export const ROLE_DELETE = 'ROLE_DELETE';
export const ROLE_ERROR = 'ROLE_ERROR';
export const ROLE_CLEAR = 'ROLE_CLEAR';

export const FIELDS_LOAD = 'FIELDS_LOAD';
export const FIELD_POST = 'FIELD_POST';
export const FIELD_PATCH = 'FIELD_PATCH';
export const FIELD_DELETE = 'FIELD_DELETE';
export const FIELD_ERROR = 'FIELD_ERROR';
export const FIELD_CLEAR = 'FIELD_CLEAR';

export const COURSES_LOAD = 'COURSES_LOAD';
export const COURSE_LOAD = 'COURSE_LOAD';
export const COURSE_POST = 'COURSE_POST';
export const COURSE_PATCH = 'COURSE_PATCH';
export const COURSE_DELETE = 'COURSE_DELETE';
export const COURSE_ERROR = 'COURSE_ERROR';
export const COURSE_CLEAR = 'COURSE_CLEAR';

export const CHATS_LOAD = 'CHATS_LOAD';
export const MESSAGES_LOAD = 'MESSAGES_LOAD';
export const MESSAGE_ADD = 'MESSAGE_ADD';
export const CHAT_SETCURRENT = 'CHAT_SETCURRENT';
export const CHAT_UPDATE = 'CHAT_UPDATE';
export const CHAT_ERROR = 'CHAT_ERROR';
export const MESSAGES_CLEAR = 'MESSAGES_CLEAR';
export const CHAT_CLEAR = 'CHAT_CLEAR';

export const SOCKET_SAVE = 'SOCKET_SAVE';
export const SOCKET_CLEAR = 'DELETE_SOCKET';
export const HISTORY_LOAD = 'HISTORY_LOAD';
export const HISTORY_ERROR = 'HISTORY_ERROR';
export const HISTORY_CLEAR = 'HISTORY_CLEAR';
export const HISTORY_LOADING = 'HISTORY_LOADING';

export const BOOKS_LOAD = 'BOOKS_LOAD';
export const BOOK_POST = 'BOOK_POST';
export const BOOK_PATCH = 'BOOK_PATCH';
export const BOOK_DELETE = 'BOOK_DELETE';
export const BOOK_ERROR = 'BOOK_ERROR';
export const BOOK_CLEAR = 'BOOK_CLEAR';

export const LOAN_LOAD = 'LOANS_LOAD';
export const LOAN_POST = 'LOAN_POST';
export const LOAN_PATCH = 'LOAN_PATCH';
export const LOAN_ERROR = 'LOAN_ERROR';
export const LOAN_CLEAR = 'LOAN_CLEAR';

export const ALUMNIS_LOAD = 'ALUMNIS_LOAD';
export const ALUMNIS_STATS_LOAD = 'ALUMNIS_STATS_LOAD';
export const ALUMNI_POST = 'ALUMNI_POST';
export const ALUMNI_ERROR = 'ALUMNI_ERROR';

export const COMPOSITION_LOAD = 'COMPOSITION_LOAD';
export const COMPOSITION_STUDENT_LOAD = 'COMPOSITION_STUDENT_LOAD';
export const COMPOSITION_STUDENT_REMOVE = 'COMPOSITION_STUDENT_REMOVE';
export const COMPOSITION_STUDENT_DISTRIBUTE = 'COMPOSITION_STUDENT_DISTRIBUTE';
export const COMPOSITION_ADDING = 'COMPOSITION_ADDING';
export const COMPOSITION_POST = 'COMPOSITION_POST';
export const COMPOSITION_PATCH = 'COMPOSITION_PATCH';
export const COMPOSITION_ERROR = 'COMPOSITION_ERROR';
export const COMPOSITION_CLEAR = 'COMPOSITION_CLEAR';
export const COMPOSITION_SELECT = 'COMPOSITION_SELECT';
export const COMPOSITION_CODE_GEN = 'COMPOSITION_CODE_GEN';
export const COMPOSITION_REMOVE_FROM_STUDENT_PROFIL =
  'COMPOSITION_REMOVE_FROM_STUDENT_PROFIL';

export const ALERT_ERROR = 'ALERT_ERROR';
export const ALERT_ABSENCES_LOAD = 'ALERT_ABSENCES_LOAD';
export const ALERT_GRADES_LOAD = 'ALERT_GRADES_LOAD';
export const ALERT_COUNT = 'ALERT_COUNT';
export const ALERT_CANCEL = 'ALERT_CANCEL';

export const ABSENCES_LOAD = 'ABSENCES_LOAD';
export const ABSENCESALERT_LOAD = 'ABSENCESALERT_LOAD';
export const ABSENCE_PATCH = 'ABSENCE_PATCH';
export const FOLLOWING_PATCH = 'FOLLOWING_PATCH';
export const ABSENCE_ERROR = 'ABSENCE_ERROR';
export const ABSENCE_USER_CLEAR = 'ABSENCE_USER_CLEAR';
export const SESSION_LOAD = 'SESSION_LOAD';
export const SESSION_ABSENCE_UPDATE = 'SESSION_ABSENCE_UPDATE';
export const ABSENCES_COMPLETION = 'ABSENCES_COMPLETION';
export const ABSENCES_STUDENT_LOAD = 'ABSENCES_STUDENT_LOAD';

export const STATISTICS_ABSENCES_LOAD = 'STATISTICS_ABSENCES_LOAD';
export const STATISTICS_ABSENCE_RATE_LOAD = 'ABSENCE_RATE_BY_CLASS';
export const STATISTICS_COORDINATOR_ABSENCE_COMPLETION_LOAD = 'STATISTICS_COORDINATOR_ABSENCE_COMPLETION_LOAD'
export const STATISTICS_GRADES_LOAD = 'STATISTICS_GRADES_LOAD';

export const PERMISSION_CHECK = 'PERMISSION_CHECK';

export const PENALTY_LOAD = 'PENALITY_LOAD';
export const PENALTY_POST = 'PENALITY_POST';
export const PENALTY_PATCH = 'PENALITY_PATCH';
export const PENALTY_DELETE = 'PENALITY_DELETE';
export const PENALTY_ERROR = 'PENALITY_ERROR';
export const PENALTY_CLEAR = 'PENALITY_CLEAR';
export const PENALTY_CURRENT_STUDENT_CHANGE = 'PENALTY_CURRENT_STUDENT_CHANGE';

export const STUDENT_REGISTRATION_INIT = 'STUDENT_REGISTRATION_INIT';
export const STUDENT_REGISTRATION_CLEAR = 'STUDENT_REGISTRATION_CLEAR';
export const STUDENT_SINGLE_LOAD = 'STUDENT_SINGLE_LOAD';
export const STUDENT_DETAILS_UPDATE = 'STUDENT_DETAILS_UPDATE';
export const STUDENT_SINGLE_CLEAR = 'STUDENT_SINGLE_CLEAR';
export const STUDENT_GRADES_LOAD = 'STUDENT_GRADES_LOAD';
export const STUDENT_HISTORY_LOAD = 'STUDENT_HISTORY_LOAD';
export const STUDENT_MEETING_POST = 'STUDENT_MEETING_POST';
export const STUDENT_MEETING_PATCH = 'STUDENT_MEETING_PATCH';


export const INTERNSHIPS_LOAD = 'INTERNSHIPS_LOAD';
export const INTERNSHIPS_ERROR = 'INTERNSHIPS_ERROR';
export const INTERNSHIPS_STATS_LOAD = 'INTERNSHIPS_STATS_LOAD';

export const REGISTRATION_LOAD = 'REGISTRATION_LOAD';
export const REGISTRATION_PATCH = 'REGISTRATION_PATCH';
export const REGISTRATION_ERROR = 'REGISTRATION_ERROR';
export const REGISTRATION_CLEAR = 'REGISTRATION_CLEAR';
export const REGISTRATION_SET_LOADING = 'REGISTRATION_SET_LOADING';
