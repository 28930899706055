import {
  STUDENT_GRADES_LOAD,
  STUDENT_HISTORY_LOAD,
  STUDENT_REGISTRATION_CLEAR,
  STUDENT_REGISTRATION_INIT,
  STUDENT_SINGLE_CLEAR,
  STUDENT_SINGLE_LOAD,
} from '../actions/types';

const initialState = {
  details: {},
  email: '',
  field: '',
  firstname: '',
  lastname: '',
  level: '',
  phoneNumber: '',
  profilepic: '',
  userType: '',
  state: '',
  studentGrades: {},
  studentHistory: [],
};

export default function user(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case STUDENT_SINGLE_LOAD:
      return payload;
    case STUDENT_SINGLE_CLEAR:
    case STUDENT_REGISTRATION_CLEAR:
      return initialState;
    case STUDENT_REGISTRATION_INIT:
      return payload;
    case STUDENT_GRADES_LOAD:
      return { ...state, studentGrades: payload };
    case STUDENT_HISTORY_LOAD:
      return { ...state, studentHistory: payload };
    default:
      return state;
  }
}
