import axios from 'axios';
import { toast } from 'react-toastify';

import {
  STUDENT_DETAILS_UPDATE,
  STUDENT_GRADES_LOAD,
  STUDENT_HISTORY_LOAD,
  STUDENT_MEETING_POST,
  STUDENT_MEETING_PATCH,
  STUDENT_REGISTRATION_CLEAR,
  STUDENT_REGISTRATION_INIT,
  STUDENT_SINGLE_CLEAR,
  STUDENT_SINGLE_LOAD,
} from './types';

import {
  adminLoadErrorMsg,
  adminPatchErrorMsg,
  adminPatchSuccessMsg,
  adminPostErrorMsg,
  adminPostSuccessMsg,
} from './alertUtils';

const modelName = 'Inscription';
const alertInsert = `${modelName.toLowerCase()}`;

const registrationInitAction = (payload) => {
  return {
    type: STUDENT_REGISTRATION_INIT,
    payload,
  };
};

const singleStudentLoadAction = (payload) => {
  return {
    type: STUDENT_SINGLE_LOAD,
    payload,
  };
};

const loadStudentGradesAction = (loadedStudentGrades) => {
  return {
    type: STUDENT_GRADES_LOAD,
    payload: loadedStudentGrades,
  };
};

const loadStudentHistoryAction = (loadedStudentHistory) => {
  return {
    type: STUDENT_HISTORY_LOAD,
    payload: loadedStudentHistory,
  };
};

const clearSingleStudentAction = () => {
  return {
    type: STUDENT_SINGLE_CLEAR,
  };
};

const updateDetailsAction = (msg) => {
  toast.success(msg);
  return {
    type: STUDENT_DETAILS_UPDATE,
  };
};

const registrationClearAction = () => {
  return {
    type: STUDENT_REGISTRATION_CLEAR,
  };
};

const userErrorAction = (msg, action) => {
  toast.error(msg);
  return {
    payload: action,
  };
};

export const regsitrationInit = (payload) => (dispatch) => {
  dispatch(registrationInitAction(payload));
};

const createStudentMeetingAction = (postedMeeting) => {
  toast.success(adminPostSuccessMsg('Rencontre'));

  return {
    type: STUDENT_MEETING_POST,
    payload: postedMeeting,
  };
};

const updateStudentMeetingAction = (patchMeting) => {
  toast.success(adminPatchSuccessMsg('Rencontre'));

  return {
    type: STUDENT_MEETING_PATCH,
    payload: patchMeting,
  };
};

export const loadMe = () => async (dispatch) => {
  axios
    .get(`/api/users/me/registering`)
    .then((res) => {
      dispatch(registrationInitAction(res.data));
    })
    .catch((_) => {
      dispatch(userErrorAction(adminLoadErrorMsg(alertInsert)));
    });
};

export const loadSingleStudent = (id) => (dispatch) => {
  axios
    .get(`/api/users/${id}`)
    .then((res) => {
      dispatch(singleStudentLoadAction(res.data));
    })
    .catch((_) => {
      dispatch(userErrorAction(adminLoadErrorMsg(modelName), 'load'));
    });
};

export const loadStudentGrades = (studentId, semester) => async (dispatch) => {
  await axios
    .get(`/api/students/${studentId}/grades`, {
      params: {
        semester,
      },
    })
    .then((res) => {
      dispatch(loadStudentGradesAction(res.data));
    })
    .catch(() => {
      dispatch(userErrorAction(adminLoadErrorMsg('Notes')));
    });
};

export const loadStudentHistory = (studentId) => async (dispatch) => {
  await axios
    .get(`/api/students/${studentId}/history`)
    .then((res) => {
      dispatch(loadStudentHistoryAction(res.data.history));
    })
    .catch(() => {
      dispatch(userErrorAction(adminLoadErrorMsg('Historique')));
    });
};

export const createStudentMeeting =
  (serialNum, meetingData) => async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify(meetingData);
    axios
      .post(`/api/students/${serialNum}/meetings`, body, config)
      .then((res) => {
        dispatch(createStudentMeetingAction(res.data));
      })
      .catch((_) => {
        dispatch(userErrorAction(adminPostErrorMsg('Rencontre')));
      });
  };


  export const updateStudentMeeting =
  (serialNum, meetingId, meetingData) => async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify(meetingData);
    axios
      .patch(`/api/students/${serialNum}/meetings/${meetingId}`, body, config)
      .then((res) => {
        dispatch(updateStudentMeetingAction(res.data));
      })
      .catch((_) => {
        dispatch(userErrorAction(adminPostErrorMsg('Rencontre')));
      });
  };

export const updateDetails =
  (details, step, setIsPendingToFalse, handleNext) => (dispatch) => {
    axios
      .patch(`/api/users/me/registering/${step}`, details)
      .then((res) => {
        if (details.state) {
          dispatch(updateDetailsAction('Votre dossier a été soumis !'));
        } else {
          dispatch(updateDetailsAction('Dossier sauvegardé avec succès !'));
        }
        dispatch(loadMe());

        if (setIsPendingToFalse) setIsPendingToFalse();
        if (handleNext) handleNext();
      })
      .catch((err) => {
        userErrorAction(adminPatchErrorMsg(modelName), 'update');
        if (setIsPendingToFalse) setIsPendingToFalse();
      });
  };

export const updateDetailsForAdmin = (id, data, step) => (dispatch) => {
  axios
    .patch(`/api/users/${id}/registering/${step}`, data)
    .then((res) => {
      if (data.state) {
        dispatch(updateDetailsAction('Mise à jour effectuée'));
      } else {
        dispatch(
          updateDetailsAction('modifications sauvegardées avec succès !')
        );
      }
      dispatch(loadSingleStudent(id));
    })
    .catch((err) => {
      userErrorAction(adminPatchErrorMsg(modelName), 'update');
    });
};

export const registrationClear = () => (dispatch) => {
  dispatch(registrationClearAction());
};

export const clearSingleStudent = () => (dispatch) => {
  dispatch(clearSingleStudentAction());
};
