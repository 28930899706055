/** @format */

import { PERMISSION_CHECK } from '../actions/types';
import { permissions } from '../utils/enums';

const initialState = {
  permissions: {
    canAddAbsence: false,
    canFollowUpAbsence: false,
    canGetAbsence: false,
    canCreateAdmin: false,
    canDeleteAdmin: false,
    canAddAdminPermissions: false,
    canUpdateAdmin: false,
    canAddAritcle: false,
    canDeleteArticle: false,
    canPublishArticle: false,
    canUpdateArticle: false,
    canManageBooks: false,
    canGenerateCompositionCode: false,
    canGenerateCompositionPaymentList: false,
    canCreateComposition: false,
    canGetComposition: false,
    canAuthorizeStudentComposition: false,
    canDeleteStudentComposition: false,
    canDistributeStudentComposition: false,
    canPayStudentComposition: false,
    canUpdateComposition: false,
    canAddCourse: false,
    canDeleteCourse: false,
    canPublishCourse: false,
    canUpdateCourse: false,
    canManageAlumni: false,
    canManageInternship: false,
    canManageCycle: false,
    canManageEvent: false,
    canManageFaq: false,
    canManageFields: false,
    canManageGalery: false,
    canReadHistory: false,
    canManageLoans: false,
    canManageOtherPage: false,
    canManagePatnersLogos: false,
    canManagePenalities: false,
    canCreateProfessor: false,
    canDeleteProfessor: false,
    canGenerateProfessorPassword: false,
    canSendMailProfessor: false,
    canUpdateProfessor: false,
    canManageSlides: false,
    canReviewStudentApplication: false,
    canCreateStudent: false,
    canDeleteStudent: false,
    canGenerateStudentPassword: false,
    canReleaseStudentPayment: false,
    canUpdateStudent: false,
    canUpdateStudentStatus: false,
    canManageTeam: false,
    canEditTexts: false,
    canGetRegistrationNumbers: false,
    canUpdateRegistration: false,
    canGetStatisticsCoordinator: false,
    canManageMeeting: false,
    canGetStatisticsAbsences: false,
    canGetStatisticsGrades: false,
    canGetStatisticsAbsenceCompletion: false,
  },
};

export default function permission(state = initialState, action) {
  const { type, payload: user } = action;

  const isActionAllowedForUser = (action) => {
    if (user && user.userType === 'superadmin') return true;
    return user.roles.some((role) => role.permissions.includes(action));
  };

  switch (type) {
    case PERMISSION_CHECK:
      return {
        ...state,
        // PERMISSION  LIST
        permissions: {
          canGetStatisticsAbsenceCompletion: isActionAllowedForUser(
            permissions.STATISTICS_ABSENCE_COMPLETION_GET
          ),
          canGetStatisticsGrades: isActionAllowedForUser(
            permissions.STATISTICS_GRADES_GET
          ),
          canGetStatisticsAbsences: isActionAllowedForUser(
            permissions.STATISTICS_ABSENCE_GET
          ),
          canManageMeeting: isActionAllowedForUser(permissions.MEETING_MANAGE),

          canGetStatisticsCoordinator: isActionAllowedForUser(
            permissions.STATISTICS_COORDINATOR_GET
          ),
          canAddAbsence: isActionAllowedForUser(permissions.ABSENCE_ADD),
          canFollowUpAbsence: isActionAllowedForUser(
            permissions.ABSENCE_FOLLOWUP
          ),
          canGetAbsence: isActionAllowedForUser(permissions.ABSENCE_GET),
          canCreateAdmin: isActionAllowedForUser(permissions.ADMIN_CREATE),
          canDeleteAdmin: isActionAllowedForUser(permissions.ADMIN_DELETE),
          canAddAdminPermissions: isActionAllowedForUser(
            permissions.ADMIN_PERMISSIONS_ADD
          ),
          canUpdateAdmin: isActionAllowedForUser(permissions.ADMIN_UPDATE),
          canAddArticle: isActionAllowedForUser(permissions.ARTICLE_ADD),
          canDeleteArticle: isActionAllowedForUser(permissions.ARTICLE_DELETE),
          canPublishArticle: isActionAllowedForUser(
            permissions.ARTICLE_PUBLISH
          ),
          canUpdateArticle: isActionAllowedForUser(permissions.ARTICLE_UPDATE),
          canManageBooks: isActionAllowedForUser(permissions.BOOKS_MANAGE),
          canGenerateCompositionCode: isActionAllowedForUser(
            permissions.COMPOSITION_CODE_GEN
          ),
          canGenerateCompositionPaymentList: isActionAllowedForUser(
            permissions.COMPOSITION_GEN_PAYMENT_LIST
          ),
          canCreateComposition: isActionAllowedForUser(
            permissions.COMPOSITION_CREATE
          ),
          canGetComposition: isActionAllowedForUser(
            permissions.COMPOSITION_GET
          ),
          canAuthorizeStudentComposition: isActionAllowedForUser(
            permissions.COMPOSITION_STUDENT_AUTHORIZE
          ),
          canDeleteStudentComposition: isActionAllowedForUser(
            permissions.COMPOSITION_STUDENT_DELETE
          ),
          canDistributeStudentComposition: isActionAllowedForUser(
            permissions.COMPOSITION_STUDENT_DISTRIBUTE
          ),
          canPayStudentComposition: isActionAllowedForUser(
            permissions.COMPOSITION_STUDENT_PAY
          ),
          canUpdateComposition: isActionAllowedForUser(
            permissions.COMPOSITION_UPDATE
          ),
          canAddCourse: isActionAllowedForUser(permissions.COURSE_ADD),
          canDeleteCourse: isActionAllowedForUser(permissions.COURSE_DELETE),
          canPublishCourse: isActionAllowedForUser(permissions.COURSE_PUBLISH),
          canUpdateCourse: isActionAllowedForUser(permissions.COURSE_UPDATE),
          canManageAlumni: isActionAllowedForUser(permissions.ALUMNI_MANAGE),
          canManageInternship: isActionAllowedForUser(
            permissions.INTERNSHIP_MANAGE
          ),
          canManageCycle: isActionAllowedForUser(permissions.CYCLE_MANAGE),
          canManageEvent: isActionAllowedForUser(permissions.EVENT_MANAGE),
          canManageFaq: isActionAllowedForUser(permissions.FAQ_MANAGE),
          canManageFields: isActionAllowedForUser(permissions.FIELDS_MANAGE),
          canManageGalery: isActionAllowedForUser(permissions.GALERY_MANAGE),
          canReadHistory: isActionAllowedForUser(permissions.HISTORY_READ),
          canManageLoans: isActionAllowedForUser(permissions.LOANS_MANAGE),
          canManageOtherPage: isActionAllowedForUser(
            permissions.OTHERPAGE_MANAGE
          ),
          canManagePatnersLogos: isActionAllowedForUser(
            permissions.PATNERSLOGOS_MANAGE
          ),
          canManagePenalities: isActionAllowedForUser(
            permissions.PENALTY_MANAGE
          ),
          canCreateProfessor: isActionAllowedForUser(
            permissions.PROFESSOR_CREATE
          ),
          canDeleteProfessor: isActionAllowedForUser(
            permissions.PROFESSOR_DELETE
          ),
          canGenerateProfessorPassword: isActionAllowedForUser(
            permissions.PROFESSOR_PASSWORD_GENERATE
          ),
          canSendMailProfessor: isActionAllowedForUser(
            permissions.PROFESSOR_SEND_MAIL
          ),
          canUpdateProfessor: isActionAllowedForUser(
            permissions.PROFESSOR_UPDATE
          ),
          canManageSlides: isActionAllowedForUser(permissions.SLIDES_MANAGE),
          canReviewStudentApplication: isActionAllowedForUser(
            permissions.STUDENT_APPLICATION_REVIEW
          ),
          canCreateStudent: isActionAllowedForUser(permissions.STUDENT_CREATE),
          canDeleteStudent: isActionAllowedForUser(permissions.STUDENT_DELETE),
          canGenerateStudentPassword: isActionAllowedForUser(
            permissions.STUDENT_PASSWORD_GENERATE
          ),
          canReleaseStudentPayment: isActionAllowedForUser(
            permissions.STUDENT_PAYMENT_RELEASE
          ),
          canUpdateStudent: isActionAllowedForUser(permissions.STUDENT_UPDATE),
          canUpdateStudentStatus: isActionAllowedForUser(
            permissions.STUDENT_STATUS_UPDATE
          ),
          canManageTeam: isActionAllowedForUser(permissions.TEAM_MANAGE),
          canEditTexts: isActionAllowedForUser(permissions.TEXTS_EDITION),
          canGetRegistrationNumbers: isActionAllowedForUser(
            permissions.REGISTRATION_GET
          ),
          canUpdateRegistration: isActionAllowedForUser(
            permissions.REGISTRATION_UPDATE
          ),
        },
      };
    default:
      return state;
  }
}
